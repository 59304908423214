import React from "react"

import { AppContextProvider } from "./src/app-context"

export const wrapRootElement = ({ element }) => {
  return <AppContextProvider>{element}</AppContextProvider>
}

// in gastby-browser.js
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}
