/* eslint-disable sort-keys */

export default {
  colors: {
    background: "#ffffff",
    body: "#050000",
    placeholder: "#969b9f",
  },
  fonts: {
    body: "Brandon Grotesque Regular",
    heading: "Brown Std Bold",
    headline: "Pilowlava Regular",
    light: "English Grotesque Light",
    thin: "English Grotesque Thin",
  },
  forms: {
    checkbox: {
      path: {
        fill: "placeholder",
      },
    },
    input: {
      border: "none",
      borderBottomColor: "placeholder",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderRadius: "0px",
      fontFamily: "body",
      fontSize: "25px",
      mb: 3,
      mt: 1,
      outline: "none",
      p: 0,
    },
    select: {
      borderColor: "placeholder",
      borderRadius: "0px",
      fontFamily: "thin",
      fontSize: "17px",
      letterSpacing: "4px",
      textTransform: "uppercase",
    },
    textarea: {
      border: "none",
      fontFamily: "body",
      fontSize: "42px",
      lineHeight: "58px",
      outline: "none",
      p: 0,
    },
  },
  layout: {
    container: {
      maxWidth: "1280px",
      px: 3,
    },
  },
  styles: {
    root: {
      color: "body",
      fontFamily: "body",
      fontSize: ["18px", "21px"],
      letterSpacing: "0px",
      lineHeight: "1.3",
      whiteSpace: "pre-line",
    },
  },
  text: {
    body: {
      fontFamily: "body",
      fontSize: ["18px", "21px"],
      letterSpacing: "0px",
      lineHeight: "1.3",
    },
    caption1: {
      fontFamily: "thin",
      fontSize: "15px",
      letterSpacing: "3px",
      // lineHeight: "100%",
      textTransform: "uppercase",
    },
    caption2: {
      fontFamily: "thin",
      fontSize: "12px",
      letterSpacing: "4px",
      // lineHeight: "100%",
      textTransform: "uppercase",
    },
    copyright: {
      fontFamily: "body",
      fontSize: ["14px", "16px"],
      letterSpacing: "0px",
      lineHeight: "1.5",
    },
    h1: {
      fontFamily: "heading",
      fontSize: ["30px", "60px"],
      lineHeight: "1.1",
      letterSpacing: "-2px",
    },
    h2: {
      fontFamily: "heading",
      fontSize: ["28px", "38px"],
      lineHeight: "1.25",
      letterSpacing: "-2px",
    },
    h3: {
      fontFamily: "heading",
      fontSize: ["25px", "28px"],
      // lineHeight: "100%",
      letterSpacing: "-1.5px",
    },
    input: {
      fontFamily: "body",
      fontSize: ["16px", "28px"],
      // letterSpacing: "0px",
      // lineHeight: "100%",
    },
    textarea: {
      fontFamily: "body",
      fontSize: ["16px", "42px"],
      // letterSpacing: "0px",
      // lineHeight: "100%",
    },
  },
}
