import React, { createContext, useState } from "react"

const AppContext = createContext({})

export const AppContextProvider = ({
  children,
}) => {
  const [appBackgroundColor, setAppBackgroundColor] = useState("")
  const [isContactModalVisible, setIsContactModalVisible] = useState(false)
  const [isMenuModalVisible, setIsMenuModalVisible] = useState(false)

  return (
    <AppContext.Provider
      value={{
        appBackgroundColor,
        isContactModalVisible,
        isMenuModalVisible,
        setAppBackgroundColor,
        setIsContactModalVisible,
        setIsMenuModalVisible,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
